.leaderboard-container {
  width: 70vw;
  max-width: 1000px;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #16171b;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  min-height: 80vh;
  padding: 2rem;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
}

.top-stats-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  .stat-box,
  .dropdown-box {
    background: #141516;
    border: 1px solid #212527;
    border-radius: 8px;
    flex: 1;
    margin: 0 0.5rem;
    padding: 1rem;
    text-align: center;
  }

  .stat-label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    opacity: 0.8;
    color: #ccc;
  }

  .stat-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
  }
}

.leaderboard-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;

  .table-scroll {
    flex: 1;
    overflow-y: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      background: #26282e;
      position: sticky;
      top: 0;
      z-index: 1;

      th {
        text-align: left;
        padding: 1rem;
        font-size: 1rem;
        border-bottom: 1px solid #444;
      }

      .text-right {
        text-align: right;
      }
    }

    tbody {
      tr {
        transition: background-color 0.2s ease;

        &:hover {
          background-color: #26282e;
        }

        td {
          padding: 1rem;
          font-size: 1rem;
          border-bottom: 1px solid #444;
          color: #fff;

          &.text-right {
            text-align: right;
          }
        }
      }

      tr.player-row-highlight {
        background: #ffffff26;
      }
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  gap: 0.5rem;

  button {
    background: #141516;
    border: 1px solid #212527;
    border-radius: 4px;
    padding: 0.5rem 0.75rem;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.2s;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.active {
      background: #26282e;
      border-color: #444;
    }

    &:hover:not(:disabled) {
      background: #26282e;
    }
  }

  span {
    color: #ccc;
    font-size: 1rem;
    padding: 0 0.5rem;
  }
}

.event-tabs {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.event-tab {
  background: #141516;
  border: 1px solid #212527;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: #fff;
  font-size: 1rem;
  transition:
    background 0.2s,
    border-color 0.2s;
}

.event-tab.active {
  background: #26282e;
  border-color: #444;
}

.event-tab:hover {
  background: #26282e;
}

@media (max-width: 768px) {
  .leaderboard-container {
    width: 90vw;
    padding: 1rem;
    margin: 2rem auto;
  }

  .top-stats-row {
    flex-direction: column;
    gap: 1rem;

    .stat-box,
    .dropdown-box {
      margin: 0.5rem 0;
      width: 100%;
    }
  }

  .leaderboard-table {
    table {
      thead {
        display: none;
      }

      tbody {
        tr {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0.5rem 1rem;
          border: 1px solid #444;
          border-radius: 8px;
          margin-bottom: 1rem;
          background-color: #141516;

          td {
            display: block;
            width: 100%;
            padding: 0.25rem 0;
            font-size: 0.9rem;
            border: none;
            position: relative;
          }

          td.text-right {
            text-align: left !important;
          }

          td:nth-of-type(1)::before {
            content: "Rank: ";
            font-weight: 600;
          }
          td:nth-of-type(2)::before {
            content: "Player: ";
            font-weight: 600;
          }
          td:nth-of-type(3)::before {
            content: "Total: ";
            font-weight: 600;
          }
        }
      }
    }
  }
}
