.balance-warning {
  background-color: #fff4f4;
  border: 1px solid #ffcccc;
  color: #cc0000;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 12px;
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.balance-warning a {
  text-decoration: underline;
  color: #000;
}