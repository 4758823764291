.menu-session {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #fff;

  .session-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2a2a2a;
    border-radius: 4px;

    .session-label {
      font-weight: 600;
      font-size: 1rem;
    }

    .session-balance {
      font-weight: 500;
    }
  }

  .session-bottom {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    background-color: #2a2a2a;
    border-radius: 4px;
    padding: 1rem;

    .session-address {
      background-color: #1b1b1b;
      border: 1px solid #444;
      border-radius: 4px;
      color: #fff;
      padding: 0.5rem 1rem;
      width: 100%;
      margin-bottom: 0.5rem;
    }

    .session-buttons {
      display: flex;
      gap: 1rem;

      button {
        background-color: #0055ff;
        border: none;
        border-radius: 4px;
        color: #fff;
        padding: 0.5rem 1rem;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          background-color: #0040cc;
        }
      }

      .btn-fund {
      }

      .btn-withdraw {
        background: transparent;
        border: 1px solid #fff;

        &:hover {
          background-color: #ffffff2b;
        }
      }
    }
  }
}

/* The switch container */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide the default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

/* The knob */
.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

/* When checked, change background color */
input:checked + .slider {
  background-color: #2196F3;
}

/* Move the knob when checked */
input:checked + .slider:before {
  transform: translateX(13px);
}

/* Rounded slider */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

