@font-face {
  font-family: 'AzoSuper';
  src: url('./fonts/AzoSuperTest.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AzoMono';
  src: url('./fonts/AzoMonoTest-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AzoSans';
  src: url('./fonts/AzoSansTest-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.menu-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.5rem;
  position: relative;
  &-left {
    .logo {
      display: flex;
      align-items: center;
      text-decoration: none;
      text-transform: uppercase;
      color: #fff;

      img {
        height: 32px;
        margin-right: 0.5rem;
      }

      span {
        font-size: 1.25rem;
        font-weight: 600;
      }
    }
  }

  &-right {
    display: flex;
    align-items: center;
    position: relative;
  }
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-right: 2rem;

  a {
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    position: relative;

    &:hover {
      color: #ff4d50;
    }

    &.active {
      color: #ff4d50;
      font-weight: 400;
      &:after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #ff4d50;
      }
    }
  }
}

.burger-menu {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: space-between;

  img {
    width: 24px;
  }

  span {
    display: block;
    width: 28px;
    height: 3px;
    background-color: #fff;
    border-radius: 2px;
  }
}

.mobile-nav-backdrop {
  display: none;
  &.open {
    display: block;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.mobile-nav {
  position: fixed;
  top: 0;
  right: -280px;
  width: 280px;
  height: 100%;
  background-color: #1e1e1e;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.2);
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: right 0.3s ease;
  z-index: 101;

  a {
    text-decoration: none;
    color: #fff;
    font-size: 1.125rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #333;

    &:hover {
      color: #ff4d50;
    }
  }

  &.open {
    right: 0;
  }
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .burger-menu {
    display: flex;
  }
}
